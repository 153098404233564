import styled from "styled-components"

const MaintenanceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  text-align: center;
  padding: 20px;
`

const Message = styled.div`
  max-width: 600px;
  font-size: 1.5em;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`

export default function Maintenance() {
  return (
    <MaintenanceWrapper>
      <Message>
        Sail routing is currently undergoing maintenance. Please check back
        later. Thank you for your patience!
      </Message>
    </MaintenanceWrapper>
  )
}
