import { useContext, useEffect, useState } from "react"
import { useGlobalStore } from "../../store/globalStore"
import Check from "../icons/Check"
import { ReactComponent as CloseIcon } from "../icons/close.svg"
import { AuthContext } from "../../contexts/authContext"
import { auth } from "../../firebase/firebase"

export default function Pricing() {
  const [betaTesterPlanIsOpen, setBetaTesterPlanIsOpen] = useState(false)
  const setShowSettings = useGlobalStore((state) => state.setShowSettings)
  const { subscriptionType, refreshUserDataSubscription, on_beta_waitlist } =
    useContext(AuthContext)
  const backendUrl = process.env.REACT_APP_BACKEND_URL
  const fetchBetaTesters = async () => {
    const token = await auth.currentUser?.getIdToken()
    let url = `${backendUrl}/user/beta_testers`
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          console.log(data)
          setBetaTesterPlanIsOpen(data.beta_testers_is_open)
        })
      }
    })
  }
  useEffect(() => {
    fetchBetaTesters()
  }, [])

  const postBetatester = async () => {
    const token = await auth.currentUser?.getIdToken()
    let url = `${backendUrl}/user/beta_tester`
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({}),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Error ${res.status}: ${res.statusText}`)
        }
        return res.json()
      })
      .then((data) => {
        console.log(data)
        alert("Congratulations! You are now a beta tester!")
        refreshUserDataSubscription()
      })
      .catch((err) => {
        alert("Sorry, the beta tester plan is not available at the moment.")
      })
  }

  const joinWaitlist = async () => {
    const token = await auth.currentUser?.getIdToken()
    let url = `${backendUrl}/user/beta_waitlist`
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({}),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Error ${res.status}: ${res.statusText}`)
        }
        return res.json()
      })
      .then((data) => {
        alert(
          "Ahoy, matey! You're officially on the waitlist to become beta tester! We’ll chart a course for you as soon as the seas open up. Stay tuned for smooth sailing!"
        )
        refreshUserDataSubscription()
      })
      .catch((err) => {
        alert("Sorry, the waitlist is not available at the moment.")
      })
  }
  return (
    <div className="p-5 max-w-[1200px] mx-auto">
      <div className="flex items-center justify-between">
        <div className="text-lg font-semibold">PLANS</div>
        <button
          onClick={() => {
            setShowSettings(false)
          }}
        >
          <CloseIcon className="h-8" />
        </button>
      </div>

      <p className="text-justify mb-8 mt-4 text-sm text-gray-600">
        Currently, we offer a single free plan for using our weather routing
        application, which includes limited features. We are actively working on
        developing additional paid versions that will provide enhanced
        functionalities and access to more advanced weather models. Stay tuned
        for updates as we expand our offerings to deliver even more
        comprehensive tools for your sailing adventures. Thank you for your
        patience and continued support.
      </p>
      <div
        id="pricing"
        className="flex text-center w-full justify-center overflow-x-auto"
      >
        <div className="rounded-lg border w-full lg:w-auto">
          <table className="min-w-full text-xs lg:text-sm">
            <thead>
              <tr className="">
                <th className="border-r border-slate-300 p-2 text-xl">
                  Features
                </th>
                <th
                  className={` border-r border-slate-300 p-2 ${
                    subscriptionType === "basic" ? "bg-red-100 border-t-4" : ""
                  }`}
                >
                  <div className="lg:p-5 p-1">
                    <div className="text-left">BASIC</div>
                    <div className="flex gap-1 items-center">
                      <div className="lg:text-2xl text-lg">$0</div>
                      <div className="text-xs text-gray-500 font-normal">
                        per user/mo
                      </div>
                    </div>
                    {subscriptionType === "basic" && (
                      <button className="cursor-default bg-red-100 border border-red-400 text-red-500 rounded-md px-3 py-1 mt-3 inline-block text-sm font-normal w-full">
                        Current Plan
                      </button>
                    )}
                  </div>
                </th>
                <th
                  className={` p-2 ${
                    subscriptionType === "betatester"
                      ? "bg-red-100 border-t-4"
                      : ""
                  }`}
                >
                  <div className="lg:p-5 p-1">
                    <div className="text-left">BETA TESTER</div>
                    <div className="flex gap-1 items-center">
                      <div className="lg:text-2xl text-lg">$0</div>
                      <div className="text-xs text-gray-500 font-normal">
                        per user/mo
                      </div>
                    </div>
                    {subscriptionType !== "betatester" && (
                      <>
                        {betaTesterPlanIsOpen && (
                          <button
                            onClick={() => {
                              postBetatester()
                            }}
                            className="bg-blue-500 hover:bg-blue-600 text-white rounded-md px-3 py-1 mt-3 inline-block text-sm font-normal w-full"
                          >
                            Upgrade
                          </button>
                        )}
                        {!betaTesterPlanIsOpen && !on_beta_waitlist && (
                          <button
                            onClick={joinWaitlist}
                            className="bg-blue-500 hover:bg-blue-600 text-white rounded-md px-3 py-1 mt-3 inline-block text-sm font-normal w-full"
                          >
                            Join the Waitlist
                          </button>
                        )}
                        {on_beta_waitlist && (
                          <button className="cursor-default bg-blue-500 hover:bg-blue-600 text-white rounded-md px-3 py-1 mt-3 inline-block text-sm font-normal w-full">
                            Already on the Waitlist
                          </button>
                        )}
                      </>
                    )}
                    {subscriptionType === "betatester" && (
                      <button className="cursor-default bg-red-100 border border-red-400 text-red-500 rounded-md px-3 py-1 mt-3 inline-block text-sm font-normal w-full">
                        Current Plan
                      </button>
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-slate-200">
                <td className="px-4 py-2 font-bold text-left border-r border-slate-300">
                  Weather Models
                </td>
                <td className="px-4 py-2 font-semibold text-left border-r border-slate-300"></td>
                <td className="px-4 py-2 font-semibold text-left"></td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-left border-r border-slate-300 text-sm">
                  GFS 0,25°
                </td>
                <td className="border-r border-slate-300">
                  <div className="px-4 py-2 flex justify-center">
                    <Check />
                  </div>
                </td>
                <td>
                  <div className="px-4 py-2 flex justify-center">
                    <Check />
                  </div>
                </td>
              </tr>
              <tr className="bg-slate-200">
                <td className="px-4 py-2 font-bold text-left border-r border-slate-300">
                  Service Area
                </td>
                <td className="px-4 py-2 font-semibold text-left border-r border-slate-300"></td>
                <td className="px-4 py-2 font-semibold text-left"></td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-left border-r border-slate-300 text-sm">
                  World
                </td>
                <td className="border-r border-slate-300">
                  <div className="px-4 py-2 flex justify-center">
                    <Check />
                  </div>
                </td>
                <td>
                  <div className="px-4 py-2 flex justify-center">
                    <Check />
                  </div>
                </td>
              </tr>
              <tr className="bg-slate-200">
                <td className="px-4 py-2 font-bold text-left border-r border-slate-300">
                  Polar
                </td>
                <td className="px-4 py-2 font-semibold text-left border-r border-slate-300"></td>
                <td className="px-4 py-2 font-semibold text-left"></td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-left border-r border-slate-300 text-sm">
                  Generic polars (mono-hull, from 6m to 20m)
                </td>
                <td className="border-r border-slate-300">
                  <div className="px-4 py-2 flex justify-center">
                    <Check />
                  </div>
                </td>
                <td>
                  <div className="px-4 py-2 flex justify-center">
                    <Check />
                  </div>
                </td>
              </tr>
              <tr className="bg-slate-200">
                <td className="px-4 py-2 font-bold text-left border-r border-slate-300">
                  Weather Routing Limit.
                </td>
                <td className="px-4 py-2 font-semibold text-left border-r border-slate-300"></td>
                <td className="px-4 py-2 font-semibold text-left"></td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-left border-r border-slate-300 text-sm">
                  Number of routings
                </td>
                <td className="px-4 py-2 text-center text-sm border-r border-slate-300">
                  Limited
                </td>
                <td className="px-4 py-2 text-center text-sm">Unlimited</td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-left border-r border-slate-300 text-sm">
                  Isochrones
                </td>
                <td className="px-4 py-2 text-center text-sm border-r border-slate-300">
                  Maximum 50 isochrones
                </td>
                <td className="px-4 py-2 text-center text-sm">
                  Maximum 50 isochrones
                </td>
              </tr>
              <tr>
                <td className="px-4 py-2 text-left border-r border-slate-300 text-sm">
                  Departure date
                </td>
                <td className="px-4 py-2 text-center text-sm border-r border-slate-300">
                  Within the next 24 hours
                </td>
                <td className="px-4 py-2 text-center text-sm">
                  no constraints
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="sm:flex sm:items-start mt-10">
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h2
            className="text-lg leading-6 font-medium text-gray-900"
            id="modal-title"
          >
            Become a Beta Tester
          </h2>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Join our exclusive Premium Beta Tester program and unlock full
              access to our advanced weather routing service. As a Premium Beta
              Tester, you'll enjoy the following benefits:
            </p>
            <ul className="list-disc list-inside mt-4 text-sm text-gray-500 text-left">
              <li>
                Unlimited Routing: Create as many routes as you need without any
                restrictions.
              </li>
              <li>
                No Departure Date Constraints: Plan your trips with complete
                freedom, without the current 24-hour limitation.
              </li>
              <li>
                Access to Extensive Boat Polars: Utilize data from hundreds of
                boat polars to optimize your journey.
              </li>
              <li>
                Provide User Feedback: Share your experiences and insights to
                help us refine and enhance our service.
              </li>
              <li>
                Contribute to Improvements: Be a part of our development process
                by suggesting new features and improvements.
              </li>
            </ul>
            <p className="text-sm text-gray-500 mt-4">
              <strong>Please note:</strong> This exclusive status is available
              for a limited time only.
            </p>
            <p className="text-sm text-gray-500 mt-4">
              Don't miss out on this unique opportunity to shape the future of
              our weather routing tool and enjoy premium features!
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
