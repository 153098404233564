import "mapbox-gl/dist/mapbox-gl.css"
import { useEffect, useRef, useState } from "react"
import Map, { MapRef, Marker, NavigationControl } from "react-map-gl"
import { ReactComponent as IconBoat } from "./icons/IconBoat.svg"
import ModuleRouting from "./Routing/ModuleRouting"
import MainOverlay from "./MainOverlay"
import { displayWindTrails } from "../utils/showWindTrails"
import DisplayColorWindForecast from "./Routing/Layers/DisplayColorWindForecast"
import { useGlobalStore } from "../store/globalStore"
import { getAndSetForecastInfo } from "../utils/forecastData"
import Sign from "./Login/Sign"
import { debounce } from "lodash"
import mapboxgl from "mapbox-gl"
import { get_data_zone } from "../utils/canvas"
import { Moment } from "moment"
import { isPointCloseToCoast, isPointOnSea } from "../utils/maps"
import Settings from "./Settings/Settings"

export default function FullMap() {
  const mapboxToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
  const mapRef = useRef<MapRef>(null)

  const setShowGradient = useGlobalStore((state) => state.setShowGradient)
  const showGradient = useGlobalStore((state) => state.showGradient)
  const forecastDate = useGlobalStore((state) => state.forecastDate)
  const forecastRasterInfo = useGlobalStore((state) => state.forecastRasterInfo)
  const setShowDefaultMapPopup = useGlobalStore(
    (state) => state.setShowDefaultMapPopup
  )
  const showDefaultMapPopup = useGlobalStore(
    (state) => state.showDefaultMapPopup
  )
  const routing = useGlobalStore((state) => state.routing)
  const setClickedPoint = useGlobalStore((state) => state.setClickedPoint)
  const showSettings = useGlobalStore((state) => state.showSettings)
  const fetchAndSetBoatName = useGlobalStore(
    (state) => state.fetchAndSetBoatName
  )
  const setMapBounds = useGlobalStore((state) => state.setMapBounds)
  const mapBounds = useGlobalStore((state) => state.mapBounds)
  const forecastData = useGlobalStore((state) => state.forecastData)
  const setCurrentPolarData = useGlobalStore(
    (state) => state.setCurrentPolarData
  )
  useEffect(() => {
    getAndSetForecastInfo()
    fetchAndSetBoatName()
    setCurrentPolarData()
  }, [])
  const handleClickOnUserMarker = (event: any) => {
    if (!event.originalEvent || !mapRef.current || !userLocation) return
    ;(event.originalEvent as MouseEvent).stopPropagation()
    const userPoint = mapRef.current.project([
      userLocation.longitude,
      userLocation.latitude,
    ])
    if (!isPointOnSea(userPoint, mapRef.current.getMap())) {
      return
    }
    setClickedPoint({
      point: userLocation,
      userIsOnSea: true,
      clickIsCLoseToCoast: false,
      clickOnUserLocation: true,
    })
    if (!routing) {
      if (showDefaultMapPopup) {
        setShowDefaultMapPopup(false)
      } else {
        setShowDefaultMapPopup(true)
      }
    }
  }

  const onMapClick = (event: mapboxgl.MapLayerMouseEvent) => {
    const map = event.target
    let clickPointCoords = {
      latitude: event.lngLat.lat,
      longitude: event.lngLat.lng,
    }

    if (!isPointOnSea(event.point, map)) {
      return
    }
    setClickedPoint({
      point: clickPointCoords,
      userIsOnSea: false,
      clickIsCLoseToCoast: isPointCloseToCoast(event.point, map),
      clickOnUserLocation: false,
    })
    if (!routing) {
      if (showDefaultMapPopup) {
        setShowDefaultMapPopup(false)
      } else {
        setShowDefaultMapPopup(true)
      }
    }
  }

  const [mapSize, setMapSize] = useState([0, 0])
  useEffect(() => {
    const handleResize = debounce(() => {
      if (mapRef.current === null) {
        return
      }
      setMapSize([
        mapRef.current?.getCanvas().width,
        mapRef.current?.getCanvas().height,
      ])
    }, 300)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  const [vectorField, setVectorField] = useState<any>(null)
  const handleDragStart = () => {
    if (vectorField !== null) {
      vectorField.stopAnimation()
    }
  }
  const handleDragEnd = () => {
    if (vectorField !== null && showGradient) {
      vectorField.startAnimation()
    }
  }

  useEffect(() => {
    // TODO: We call two times displayWindTrails, useMemo ? to not call twice and detect if data is the same
    if (mapRef.current === null) {
      return
    }

    if (showGradient && forecastData) {
      setVectorField((prevVectorField: any) => {
        if (prevVectorField !== null) {
          prevVectorField.stopAnimation()
        }
        const res = displayWindTrails(mapRef, forecastData)
        return res
      })
    } else {
      if (vectorField !== null) {
        vectorField.stopAnimation()
        setVectorField(null)
      }
      if (mapRef.current.getMap().getLayer("windTrails") !== undefined) {
        mapRef.current.getMap().removeLayer("windTrails")
      }
    }
  }, [showGradient, mapRef, mapSize, forecastData])

  useEffect(() => {
    if (routing) {
      setShowGradient(false)
    }
  }, [routing])

  const onLoad = () => {
    handleLoadingIcons()
  }
  const handleLoadingIcons = () => {
    if (mapRef.current === null) {
      return
    }
    const map = mapRef.current.getMap()

    map.loadImage("images/wind-arrow-white.png", (error, image) => {
      if (error) throw error
      if (!map.hasImage("wind-icon") && image) {
        map.addImage("wind-icon", image)
      }
    })
    map.loadImage("images/propeller_white.png", (error, image) => {
      if (error) throw error
      if (!map.hasImage("propeller-icon") && image) {
        map.addImage("propeller-icon", image)
      }
    })
  }
  const [viewState, setViewState] = useState({
    latitude: 50,
    longitude: -2,
    zoom: 7,
  })
  const [userLocation, setUserLocation] = useState<{
    latitude: number
    longitude: number
  } | null>(null)

  const geoError = (error: GeolocationPositionError) => {
    console.error("Error occurred while fetching geolocation: ", error)
  }
  useEffect(() => {
    const moveViewToUserLocation = (position: GeolocationPosition) => {
      const lat = position.coords.latitude
      const lon = position.coords.longitude
      if (lat > 29 && lat < 61 && lon > -16 && lon < 41) {
        setViewState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          zoom: 7,
        })
      }
    }
    const updateUserLocation = (position: GeolocationPosition) => {
      // user location is updated when precision is better or user is moving
      setUserLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      })
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(moveViewToUserLocation, geoError)
      const watchId = navigator.geolocation.watchPosition(
        updateUserLocation,
        geoError,
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      )
      return () => {
        navigator.geolocation.clearWatch(watchId)
      }
    } else {
      console.error("Geolocation is not supported by this browser.")
    }
  }, [])
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap()
      const bounds = map.getBounds()
      setMapBounds(bounds)
      map.on("moveend", () => {
        const bounds = map.getBounds()
        setMapBounds(bounds)
      })
    }
  }, [mapRef.current])

  const [zone, setZone] = useState<number[]>([0, 0, 0, 0])
  const [forecastDateRequested, setForecastDateRequested] = useState<
    Moment | undefined
  >(undefined)
  const setForecastData = useGlobalStore((state) => state.setForecastData)
  const [forceGetData, setForceGetData] = useState(false)
  const [isForecastDataLoading, setIsForecastDataLoading] = useState(false)
  const [showForecastZoomMessage, setShowForecastZoomMessage] = useState(false)
  useEffect(() => {
    if (!forecastDate) return
    if (!mapBounds) return
    if (!showGradient) return
    if (mapRef.current) {
      const map = mapRef.current.getMap()
      const zoomLevel = map.getZoom()
      if (zoomLevel < 5) {
        setForecastData(undefined)
        setForceGetData(true)
        setShowForecastZoomMessage(true)
        return
      } else {
        setShowForecastZoomMessage(false)
      }
    }
    // check if we are within the zone already requested
    if (
      !(
        mapBounds.southWest.longitude < zone[0] ||
        mapBounds.southWest.latitude < zone[1] ||
        mapBounds.northEast.longitude > zone[2] ||
        mapBounds.northEast.latitude > zone[3]
      ) &&
      forecastDateRequested === forecastDate &&
      forceGetData === false
    ) {
      return
    }
    const { minx, miny, maxx, maxy } = get_data_zone(mapBounds)
    setForecastData(undefined)
    setIsForecastDataLoading(true)
    setForceGetData(false)
    setZone([minx, miny, maxx, maxy])
    setForecastDateRequested(forecastDate)
    const forecast_date = forecastDate.format()
    const backendUrl = process.env.REACT_APP_BACKEND_URL
    let url = `${backendUrl}/forecast/data`
    const inputData = {
      forecast_date,
      model: "GFS_0p25",
      box: {
        NW: { latitude: maxy, longitude: minx },
        SE: { latitude: miny, longitude: maxx },
      },
    }
    url += `?forecast_date=${forecast_date}`
    url += `&model=GFS_0p25`
    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(inputData),
    })
      .then((response) => response.json())
      .then((data) => {
        setForecastData(data)
        setIsForecastDataLoading(false)
      })
      .catch((error) => console.error("Error fetching", error))
  }, [forecastDate, mapBounds, showGradient])

  return (
    <>
      <div className="grow relative font-sans">
        <Map
          ref={mapRef}
          mapboxAccessToken={mapboxToken}
          mapStyle="mapbox://styles/francoisrobert/clw7j1skn02ea01o0ckjq3img"
          {...viewState}
          style={{ width: "100%", height: "100%" }}
          onClick={onMapClick}
          onLoad={onLoad}
          onMove={(evt) => setViewState(evt.viewState)}
          dragRotate={false} // Désactiver la rotation par glissement
          touchZoomRotate={true} // Désactiver la rotation tactile
          trackResize={true} // ?
          onDragStart={() => handleDragStart()}
          onDragEnd={() => handleDragEnd()}
          onZoomStart={() => handleDragStart()}
          onZoomEnd={() => handleDragEnd()}
          maxZoom={13}
          logoPosition="top-left"
        >
          <NavigationControl
            showCompass={true}
            position="bottom-right"
            visualizePitch={true}
          />

          <ModuleRouting mapRef={mapRef} />
          {showGradient && forecastDate && (
            <DisplayColorWindForecast forecastDate={forecastDate} />
          )}
          {userLocation && (
            <Marker
              latitude={userLocation.latitude}
              longitude={userLocation.longitude}
              offset={[0, 0]}
              onClick={handleClickOnUserMarker}
            >
              <IconBoat />
            </Marker>
          )}
        </Map>
        <MainOverlay forecastRasterInfo={forecastRasterInfo} />
        {isForecastDataLoading && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <h2 className="text-sm sm:text-xl font-bold">
                Forecast loading...
              </h2>
            </div>
          </div>
        )}
        {showForecastZoomMessage && showGradient && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 pointer-events-none">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center">
              <h2 className="text-sm sm:text-xl font-bold">
                To view weather data, please zoom in on the map
              </h2>
            </div>
          </div>
        )}
        <Sign />
        {showSettings && <Settings />}
      </div>
    </>
  )
}
