import { useEffect, useState } from "react"
import { CalculationInfo } from "../../../utils/types"
import messages from "../../messages/waiting_messages.json"
import { ErrorMessage } from "../ModuleRouting"
import { useGlobalStore } from "../../../store/globalStore"
export default function ModalRoutingLoading({
  errorMessage,
  setRoutingIsLoading,
  setErrorMessage,
  calculationInfo,
}: {
  errorMessage: ErrorMessage | null
  setRoutingIsLoading: (arg: boolean) => void
  setErrorMessage: (arg: ErrorMessage | null) => void
  calculationInfo: CalculationInfo | null
}) {
  const [currentIndex, setCurrentIndex] = useState(
    Math.floor(Math.random() * messages.eng.length)
  )
  const setSectionSettings = useGlobalStore((state) => state.setSectionSettings)
  const setShowSettings = useGlobalStore((state) => state.setShowSettings)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % messages.eng.length)
    }, 10000) // 10000ms = 10 seconds

    return () => clearInterval(intervalId) // Clean up the interval on component unmount
  }, [])

  const [remainingSeconds, setRemainingSeconds] = useState<number | null>(null)

  useEffect(() => {
    if (
      errorMessage &&
      errorMessage.type === "userRequestLimit" &&
      errorMessage.timeUntilNextRoutingAllowedInSeconds
    ) {
      const intervalId = setInterval(() => {
        setRemainingSeconds((prev) =>
          prev !== null
            ? Math.max(prev - 1, 0)
            : errorMessage.timeUntilNextRoutingAllowedInSeconds
        )
      }, 1000) // 1000ms = 1 second
      return () => clearInterval(intervalId) // Clean up the interval on component unmount
    }
  }, [errorMessage])

  useEffect(() => {
    if (remainingSeconds === 0) {
      setRoutingIsLoading(false)
      setErrorMessage(null)
    }
  }, [remainingSeconds, setRoutingIsLoading, setErrorMessage])

  return (
    <div className="absolute z-10 top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-xl shadow-xl w-[500px] p-8">
        {errorMessage === null && (
          <>
            <div className="text-xl font-bold">
              {calculationInfo && <div>{calculationInfo.info}</div>}
            </div>
            <div className="flex justify-center items-center mt-4">
              <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
            <div className="w-full border-gray-400 border-t my-2" />
            <div className="text-base font-medium mb-2">Tips:</div>
            <div>{messages.eng[currentIndex]}</div>
            <div className="w-full border-gray-400 border-t my-2" />
          </>
        )}
        {errorMessage !== null && errorMessage.type !== "userRequestLimit" && (
          <div className="flex flex-col gap-4">
            <div className="text-center text-3xl font-bold">Oops!</div>
            <div className="sm:text-lg">
              It looks like there was an issue with the routing request. For
              specific details on what went wrong, check the error message
              displayed below.
            </div>
            <div className="text-sm text-red-600 font-semibold">
              Error message: {errorMessage.message}
            </div>
            <div className="text-center">
              <button
                className="bg-red-500 text-white text-sm font-bold px-4 py-2 rounded-md w-1/4"
                onClick={() => {
                  setRoutingIsLoading(false)
                  setErrorMessage(null)
                }}
              >
                Ok
              </button>
            </div>
          </div>
        )}
        {errorMessage !== null && errorMessage.type === "userRequestLimit" && (
          <div className="flex flex-col gap-4">
            <div className="text-center text-3xl font-bold">Ahoy, Captain!</div>
            {remainingSeconds !== null && (
              <div className="flex justify-center ">
                <div className="flex gap-1 items-center content-center text-lg">
                  <div className="">Next routing: </div>
                  <div className="font-bold">{remainingSeconds} seconds</div>
                </div>
              </div>
            )}

            <div className="sm:text-sm">
              It looks like you've reached the routing limit for your current
              plan. Since you're on the Basic Plan, you'll need to wait a bit
              before starting a new route. However, you can remove this limit by
              upgrading your plan.
            </div>
            <button
              onClick={() => {
                setRoutingIsLoading(false)
                setErrorMessage(null)
                setSectionSettings("plans")
                setShowSettings(true)
              }}
              className="text-left text-sm font-semibold underline"
            >
              Upgrade my plan
            </button>

            <div className="text-center">
              <button
                className="bg-red-700 text-white text-sm font-bold px-4 py-2 rounded-md w-1/4"
                onClick={() => {
                  setRoutingIsLoading(false)
                  setErrorMessage(null)
                }}
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
