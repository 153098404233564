import { useGlobalStore } from "../../../store/globalStore"
import DisplayIsochrones from "./DisplayIsochrones"
import DisplayPath from "./DisplayPath"
import DisplayRoutePoints from "./DisplayRoutePoints"
import NoRoutingClickPopup from "./NoRoutingClickPopup"

export default function RoutingLayers({
  directRouteLine,
}: {
  directRouteLine: any
}) {
  const routing = useGlobalStore((state) => state.routing)
  const routePoints = useGlobalStore((state) => state.routePoints)
  const setRoutePoints = useGlobalStore((state) => state.setRoutePoints)

  return (
    <>
      <DisplayRoutePoints
        routePoints={routePoints}
        setRoutePoints={setRoutePoints}
        directRouteLine={directRouteLine}
        routing={routing}
      />
      {routing ? (
        <>
          {/* <DisplayIsochronePaths routing={routing} /> */}
          <DisplayIsochrones routing={routing} />
          <DisplayPath routing={routing} />
        </>
      ) : (
        <NoRoutingClickPopup
          setRoutePoints={setRoutePoints}
          routePoints={routePoints}
        />
      )}
    </>
  )
}
