import { useEffect, useState } from "react"
import { auth } from "../../firebase/firebase"
import { PolarBase, PolarData } from "../../utils/types"
import PolarGraph from "../PolarGraph/PolarGraph"
import { useGlobalStore } from "../../store/globalStore"
import { ReactComponent as CloseIcon } from "../icons/close.svg"

export default function BoatSettings() {
  const [polarData, setPolarData] = useState<PolarData | undefined>(undefined)
  const [polarBaseList, setPolarBaseList] = useState<PolarBase[]>([])
  const [polarDiagramData, setPolarDiagramData] = useState<
    [[number, number[], number[]]] | undefined
  >(undefined)
  const backendUrl = process.env.REACT_APP_BACKEND_URL
  const setShowSettings = useGlobalStore((state) => state.setShowSettings)
  const fetchAndSetBoatName = useGlobalStore(
    (state) => state.fetchAndSetBoatName
  )
  const setCurrentPolarData = useGlobalStore(
    (state) => state.setCurrentPolarData
  )

  async function getBasePolarList() {
    const token = await auth.currentUser?.getIdToken()
    if (token) {
      fetch(`${backendUrl}/polar/base-polar-list/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setPolarBaseList(data)
        })
    }
  }

  async function getPolar() {
    const token = await auth.currentUser?.getIdToken()
    if (token) {
      fetch(`${backendUrl}/polar/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data: PolarData) => {
          setPolarData(data)
        })
    }
  }

  async function savePolar() {
    const token = await auth.currentUser?.getIdToken()

    if (token && polarData) {
      fetch(`${backendUrl}/polar/${polarData.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(polarData),
      })
        .then((response) => response.json())
        .then(() => {
          fetchAndSetBoatName()
          setCurrentPolarData()
          setShowSettings(false)
        })
    }
  }

  async function getPolarData() {
    console.log(polarData)
    if (!polarData) return
    const token = await auth.currentUser?.getIdToken()
    if (token) {
      let url = `${backendUrl}/polar/data/`
      url += `?polar_base_id=${polarData?.polar_base_id}`
      url += `&perf_factor=${polarData?.perf_factor}`
      url += `&has_spi=${polarData?.has_spi}`
      if (polarData?.beat_angle) {
        url += `&beat_angle=${polarData?.beat_angle}`
      }
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setPolarDiagramData(data)
        })
    }
  }
  useEffect(() => {
    getPolarData()
  }, [polarData])

  useEffect(() => {
    getPolar()
  }, [])

  useEffect(() => {
    getBasePolarList()
  }, [])

  return (
    <div className="bg-white p-5 w-full h-full flex flex-col gap-2 overflow-y-auto">
      <div className="flex items-center justify-between">
        <div className="text-lg font-semibold">MY BOAT</div>
        <button
          onClick={() => {
            setShowSettings(false)
          }}
        >
          <CloseIcon className="h-8" />
        </button>
      </div>

      {polarData && (
        <div className="flex gap-7 md:justify-center items-center md:items-start flex-col md:flex-row">
          <div className="grow w-full">
            <div className="flex flex-col gap-3 ">
              <div className="flex flex-col gap-1">
                <select
                  className="border rounded p-1"
                  value={polarData.polar_base_id}
                  onChange={(e) => {
                    setPolarData({
                      ...polarData,
                      polar_base_id: parseInt(e.target.value),
                    })
                  }}
                >
                  {polarBaseList.map((polarBase) => (
                    <option value={polarBase.id} key={polarBase.id}>
                      {polarBase.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col gap-1">
                <div className="text-sm font-semibold text-gray-600">
                  PERFORMANCE ADJUSTMENT
                </div>
                <div className="flex items-center gap-2">
                  <button
                    className="border rounded p-1"
                    onClick={() => {
                      setPolarData({
                        ...polarData,
                        perf_factor: Math.max(
                          Math.round((polarData.perf_factor - 0.05) * 100) /
                            100,
                          0.5
                        ),
                      })
                    }}
                  >
                    -
                  </button>
                  <span>{(polarData.perf_factor * 100).toFixed(0)} %</span>
                  <button
                    className="border rounded p-1"
                    onClick={() => {
                      setPolarData({
                        ...polarData,
                        perf_factor: Math.min(
                          Math.round((polarData.perf_factor + 0.05) * 100) /
                            100,
                          1.5
                        ),
                      })
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="flex gap-2">
                <input
                  type="checkbox"
                  checked={polarData.has_spi}
                  onChange={() => {
                    setPolarData({
                      ...polarData,
                      has_spi: polarData.has_spi ? false : true,
                    })
                  }}
                ></input>
                <div className="text-sm font-semibold text-gray-600">
                  DOES YOUR BOAT HAVE SPINNAKER?
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div className="flex gap-2">
                  <input
                    type="checkbox"
                    checked={polarData.beat_angle ? true : false}
                    onChange={() => {
                      setPolarData({
                        ...polarData,
                        beat_angle: polarData.beat_angle ? null : 45,
                      })
                    }}
                  ></input>
                  <div className="text-sm font-semibold text-gray-600">
                    NEED TO ADJUST YOUR BEAT ANGLE (°)
                  </div>
                </div>
                {polarData.beat_angle && (
                  <div className="flex items-center gap-2">
                    <button
                      className="border rounded p-1"
                      onClick={() => {
                        setPolarData({
                          ...polarData,
                          beat_angle: Math.max(
                            (polarData.beat_angle ?? 45) - 1,
                            35
                          ),
                        })
                      }}
                    >
                      -
                    </button>
                    <span>{polarData.beat_angle} °</span>
                    <button
                      className="border rounded p-1"
                      onClick={() => {
                        setPolarData({
                          ...polarData,
                          beat_angle: Math.min(
                            (polarData.beat_angle ?? 45) + 1,
                            80
                          ),
                        })
                      }}
                    >
                      +
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex items-center justify-center gap-4 mt-5">
              <button
                className=" px-3 py-1 rounded font-semibold border border-gray-600 hover:bg-gray-100"
                onClick={() => setShowSettings(false)}
              >
                CANCEL
              </button>
              <button
                onClick={savePolar}
                className="bg-gray-600 px-3 py-1 rounded text-white font-semibold hover:bg-gray-700"
              >
                SAVE
              </button>
            </div>
          </div>
          <div className="w-[300px]">
            <div className="border-t border-gray-300"></div>
            <PolarGraph polarDiagramData={polarDiagramData} />
            <div className="border-t border-gray-300"></div>
            <div className="text-sm mt-2">
              The Speed Polar Diagram, or simply 'polar', is an essential tool
              for sailors. It displays the potential speeds of a sailboat at
              various wind angles and intensities. This circular graph
              represents different wind directions relative to the boat and
              shows the optimal speed your sailboat can achieve under specific
              wind conditions.
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
