import { useState } from "react"
import { auth } from "../../firebase/firebase"

export default function MessageModal({ onClose }: { onClose: () => void }) {
  const [message, setMessage] = useState("")
  const [isSent, setIsSent] = useState(false)

  const handleSend = async () => {
    try {
      const token = await auth.currentUser?.getIdToken()
      const backendUrl = process.env.REACT_APP_BACKEND_URL
      const response = await fetch(`${backendUrl}/message/create`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ content: message }),
      })
      if (!response.ok) {
        throw new Error("Failed to send message")
      }
      setIsSent(true)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white rounded-lg shadow-lg max-w-md w-full p-6">
        <h2 className="text-xl font-bold mb-4">Contact SailRouting Team</h2>

        {isSent ? (
          <>
            <p>Message sent successfully</p>
            <span className="text-sm text-gray-500">
              We will get back to you as soon as possible.
            </span>
            <div className="flex justify-center mt-6">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                onClick={onClose}
              >
                Ok
              </button>
            </div>
          </>
        ) : (
          <div>
            <textarea
              className="w-full p-2 border rounded-lg mb-4"
              rows={5}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Enter your message here..."
            />
            <div className="flex justify-end gap-2">
              <button
                className="bg-gray-300 text-black px-4 py-2 rounded-lg"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-lg"
                onClick={handleSend}
              >
                Send
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
