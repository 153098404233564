import FullMap from "./components/FullMap"
import Header from "./components/Header"
import Maintenance from "./components/Maintenance"
import { AuthProvider } from "./contexts/authContext"
const isMaintenance = false
function App() {
  return (
    <AuthProvider>
      {isMaintenance ? (
        <Maintenance />
      ) : (
        // h-svh -> mobile screen to adapt height screen when action buttons
        <div className="font-medium flex flex-col h-svh">
          <Header />
          <FullMap />
        </div>
      )}
    </AuthProvider>
  )
}

export default App
